.cardName_pos{
   position: relative;
   top: 50px;
   left: 77px;
 }
 .cardPhone_pos{
   position: relative;
   left: 115px;
   top: 49px;
 }
 .cardCons_pos{
   position: relative;
   left: 133px;
   top: 52px;
 }
 .cardId_pos{
   position: relative;
   left: 74px;
   top: 44px;
 }
 .f-9{
   font-size: 9px;
   margin-left: 0px !important;
 }
 .ln-30{
   line-height: 25px;
 }
 .qr_code_pos{
   position: absolute;
   top: 110px;
   left: 18px;
 }
.flex-header-benifits-view{
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}
.w_90{
   width: 90px;
}
.flex-hero-text-view{
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   align-items: center;
}
.text-hero{
   font-size: 14px;
   line-height: 27px;
   text-align: center;
   font-weight: bold;
}
.w_150{
   width: 150px;
}
.pos_sign_top{
   position: relative;
   top: -17px;
}
.border_red{
   border: 2px solid #ff2e17;
}
.bg_light_yellow{
   background-color: #fffdeb;
}
.border_right{
   border-left: 2px solid #000;
}
.profile_details p{
   line-height: 15px;
   font-size: 14px;
}
.unique_code{
   background-color: #ffd047;
   padding: 5px;
}
.table_custom thead tr th{
   background-color: #DE1D24;
   font-size: 13px;
   vertical-align: middle;
   text-align: center;
   color: #fff;
   padding: 3px;
}
.table_custom tbody tr td{
   background-color: #fffdeb;
   font-size: 13px;
   text-align: center;
   font-weight: bold;
   padding: 3px;
}
.schemesListCls li {
   margin-left: 10px;
   font-size: 14px;
   margin-top: 5px;
   display: inline-block;
}
.main_text_pos1{
   left: 50px;
   top: 40px;
}
.nonFinancialCls li{
   font-size: 13px;
   margin-top: 5px;
   padding-left: 15px;
   font-weight: bold;
}
.ml_5{
   margin-left: 5px;
}
.width_height_small1{
   width: 60px;
}
.back_color_shades{
  border-top: 15px solid #e10707;
  border-right: 15px solid #ffe45e;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #e10707;
  border-image: linear-gradient(77deg, rgba(225,7,7,1) 53%, rgba(19,82,6,1) 63%, rgba(255,228,94,1) 74%);
  border-image-slice: 1;
  width: 100%;
}
.f-22{
  font-size: 22px;
}
.mt_5{
  margin-top: 4rem;
}
.mt_4{
  margin-top: 3rem;
}
.ln-28{
  line-height: 32px;
}
.pos_name_align input{
  position: relative;
  top: -2px;
}
.color_red{
  color:red;
}
.color_green{
  color: green;
}
.f-42{
  font-size: 6rem;
}
.f-18{
  font-size: 18px;
}
.f-22{
  font-size: 20px;
}