.bg_image_home{
    display: block;
    width: 100%;
    background: url('../../images/bg.png') no-repeat;
    background-repeat: repeat;
}
.flex-align-hero-section{
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-evenly;
}
.color_yellow{
    color: #FFDD3C;
}
.image_position {
    transform: translateY(30%);
    position: relative;
    left: -5rem;
}
.image_position1 {
    transform: translateY(10%);
    position: relative;
    left: -5rem;
}
.f-45{
    font-size: 38px;
    font-style: italic;
}
.f-35{
    font-size: 28px;
}
.f-italic{
    font-style: italic;
}
.mt_2_5{
    margin-top: 2rem;
}
.bg_cbn_img{
    background: url('../../images/cbn_img.png') no-repeat;
    background-position: bottom 1px right 20px;
    
}
.bg_yellow{
    background: #FFDD3C;
    padding: 15px;
}
.border_bottom_green{
    border-bottom: 10px solid #00A652;
}
.bg_light_yellow{
    background: #FFFBDB;
}
.bg_light_yellow_p{
    background: #fffdeb;
}
.color_red{
    color: #DE1D24;
}
.reg_form_css{
    background: #FFFFFF;
    border: 2px solid #D12124;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
}
.f-12{
    font-size: 12px;
}
.text_border_css{
    background-color: #fad93b;
    border: 2px solid #D12124;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    padding: 10px;
}
.height_390{
    height: 363px;
}
.pad_50{
    padding: 50px;
}
.text_font h4{
    font-size: 12px;
    font-weight: bold;
    color: #000;
    line-height: 25px;
}
.text_font h5{
    font-size: 16px;
    font-weight: bold !important;
    text-decoration: underline;
}

.mobile_view_none{
    display: block !important;
}
.mobile_view_show{
    display: none !important;
}
.f-40{
    font-size: 40px;
}
.bg_MS{
    background: linear-gradient(98.76deg, #9D1B35 2.12%, #56143D 81.65%);
    padding: 5rem;

}
.f-18{
    font-size: 18px;
}
.list_type_css li{
    font-size: 16px;
    margin-top: 10px;
    color: #fff;
    line-height: 32px;
}
.center-auto{
    display: block;
    margin: auto;
}
.flex-blocks-manifest-list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: start;
}
.pad_5rem{
    padding: 5rem;
}
.bg_annaData{
    background: linear-gradient(102.91deg, #18BA57 41.48%, #0C3E3D 95.28%);
}
.bg_YG{
    background: linear-gradient(249.6deg, #E35995 20%, #433F89 60.84%);
}
.bg_HW{
    background: linear-gradient(101.1deg, #2E7E75 32.66%, #E2E4E1 86.92%);
}
.bg_PTR{
    background: linear-gradient(108.76deg, #A43B8D 14.18%, #072162 58.43%);
}
.bg_BC{
    background: linear-gradient(97.23deg, #EE7316 13.02%, #900B11 51.48%);
}
.card-social-news {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 40px #00000029;
    border-radius: 5px;
}
.bg_white{
    background-color: #fff;
    padding: 15px;
}
.twitter_height_width{
    position: static; visibility: visible; width: 380px; height: 485px; display: block; flex-grow: 1;
}
.insta_height_widtg{
    background: white; max-width: 400px; height: 475px; width: calc(100% - 2px); border-radius: 3px; border: 1px solid rgb(219, 219, 219); box-shadow: none; display: block; margin: 0px 0px 12px; min-width: 326px; padding: 0px;
}
.fb_height_widtg{
    border: medium none; visibility: visible; width: 380px; height: 480px;
}
.pad_50{
    padding: 50px;
}
.float_right{
    float: right;
}
.mt_6{
    margin-top: 3.4rem;
}
.width_height_small{
    width: 45px;
    height: auto;
}
.text_font h5 img{
    border-bottom: 2px solid #de1d24;
}
.w_res1_100{
    width: 80%;
}
.f-14{
    font-size: 14px;
}
.hash_tag_css li{
    display: inline-block;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 10px;
    font-size:14px ;
    color: #DE1D24;
    font-weight: 500;
}
@media screen and (max-width: 767px) {
    .mobile_view_none{
        display: none !important;
    }
    .mobile_view_show{
        display: block !important;
    }

    .flex-align-hero-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .w_res_100{
        width: 100%;
    }
    .image_position {
        transform: translateY(0%);
    }
    .image_position1 {
        transform: translateY(0%);
    }
    .f-45{
        font-size: 32px;
        font-style: italic;
    }
    .f-35{
        font-size: 18px;
    }
    .text_center_align{
        text-align: center;
    }
    .bg_cbn_img{
        background: none;
        background-position: none;
        margin-bottom: 30px;
        
    }
    .mt_2_5{
        margin-top: 1rem;
    }
    .f-14-res{
        font-size: 14px;
    }
    .line-height-res-32{
        line-height: 32px;
    }
    .height_390{
        height: auto;
    }
    
    .text_font h4{
        font-size: 14px;
        font-weight: bold;
        color: #000;
        margin-top: 20px;
        line-height: 25px;
    }
    .flex-blocks-manifest-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    br{
        display: none;
    }
    .pad_5rem{
        padding: 1rem;
    }
    .pad_30{
        padding: 10px;
    }
    .pad_50{
        padding: 10px;
    }
    .twitter_height_width{
        position: static; visibility: visible; width: 100%; height: 485px; display: block; flex-grow: 1;
    }
    .insta_height_widtg{
        background: white; max-width: 100%; height: 475px;  border-radius: 3px; border: 1px solid rgb(219, 219, 219); box-shadow: none; display: block; margin: 0px 0px 12px; min-width: 326px; padding: 0px;
    }
    .fb_height_widtg{
        border: medium none; visibility: visible; width: 100%; height: 480px;
    }
    .image_position {
        position: relative;
        left: 0;
    }
    .image_position1 {
        position: relative;
        left: 0;
    }
    .mt_6{
        margin-top: 2rem;
    }
    .w_res1_100{
        width: 100%;
    }
    

}