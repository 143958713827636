
  .back_button{
    position: relative;
    top: -43px;
  }
  .pos_relative{
    position: relative;
  }
  .w_90_R{
    width: 70px;
  }
  .w_60_R{
    width: 60px;
  }
  .input_size{
    font-size: 18px;
    position: relative;
    right: -3px;
    top: 3px;
  }
  .slide_one_css{
    transition-duration: .5s;
    transition-property: transform, opacity;
  }
  .slideLeft {
    transform: translate(-100%, 0);
    opacity: 0;
  }
  .f-28{
    font-size: 28px;
  }
  .scanner_css{
    margin-top: -10px;
    cursor: pointer;
    color: green;
  }
 .view_enroll_css{
    text-decoration: underline;
    color: #0d6efd !important;
    cursor: pointer;
 }
 .text_center{
    text-align: center;
 }
 .m_top50{
    margin-top: 40px;
 }
 .f-34{
    font-size: 140px;
 }
 .f-10{
    font-size: 11px;
 }
 .flex-interests-align{
  display: flex;
  flex-direction: row;
  align-items: center;
 }
 input::placeholder{
  font-size: 12px;
 }
 .mt_1{
  margin-top: -3px;
 }
 .f-20{
  font-size: 20px;
 }
 .ml_28{
  margin-left: 10px;
 }
 #maha_shakti_id{
  margin-top: 8px;
 }
 #yuva_galam_id{
  margin-top: 10px;
 }
 #ad_id{
  margin-top: 12px;
 }
 #house_water_id,#ptr_id,#bc_id{
  margin-right: 5px;
}
.ml_15{
 margin-left: -0px;
}
.float_right{
  float: right;
}
.color_green{
  color: green;
}
.w_55_R{
  width: 65px;
}
.mahashaktiPosCls{
  position: relative;
  top: -10px;
}
#ad_id{
  position: relative;
  top: 15px;
}
.pos_left_align{
  position: relative;
  left: 5px;
}
.countdown-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.line_height_20{
  line-height: 20px;
}
.get_otp_loading_symbol_cls{
  width: 20px;
  height: 20px;
  float: right;
  position: relative;
  top: 12px;
}
.loader_cls_otp{
  width: 20px;
  height: 20px;
  float: right;
  position: relative;
  top: -25px;
  left: -18px;
}
.get_otp_loading_symbol_cls{
  width: 20px;
  height: 20px;
  float: right;
  position: relative;
  top: 12px;
}
.main_text_pos{
  left: 76px;
  top: 67px;
}
.cardName_pos1{
  position: relative;
  top: 22px;
  left: 52px;
}
.cardPhone_pos1{
  position: relative;
  left: 87px;
  top: 22px;
}
.cardCons_pos1{
  position: relative;
  left: 100px;
  top: 20px;
}
.cardId_pos1{
  position: relative;
  left: 55px;
  top: 20px;
}
.f-9{
  font-size: 9px;
  margin-left: 0px !important;
}
.ln-30{
  line-height: 25px;
}
.qr_code_pos1{
  position: absolute;
  top: 116px;
  left: 20px;
}
.f-9{
  font-size: 9px;
  margin-left: 0px !important;
}
.input_size_MS{
  font-size: 18px;
  position: relative;
  right: -3px;
  top: -12px
}
.input_size_AD{
  font-size: 18px;
  position: relative;
  right: -11px;
  top: 3px;
}
.color_black{
  color: #000;
}