.header-flex-align{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.menu-options li{
    display: inline-block;
}
.bg_image_navbar{
    width: 100%;
    background: url('../images/bg.png') no-repeat;
    background-repeat: repeat;
    border-bottom: 1px solid rgba(255, 255, 255, 0.42);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.02));
}
.login_btn_yellow{
    background: #FFED00;
    border-radius: 8px;
    color: #E30613;
}
.nav-link{
    color: #fff !important;
    font-weight: bold;
    margin-left: 30px;
}
.active{
    border-bottom: 1px solid #fff;
}
.nav-link:hover{
    color: #fff;
    border-bottom: 1px solid #fff;
}
.navbar-toggler{
    border:2px solid #fff;
}
.color_white{
    color: #fff;
}
.mobile_view_none{
    display: block !important;
}
.mobile_view_show{
    display: none !important;
}
.ml_8rem{
    margin-left: 8rem;
}

@media screen and (max-width: 767px) {
    .mobile_view_none{
        display: none !important;
    }
    .mobile_view_show{
        display: block !important;
    }
    .title_pos_mobile{
        position: relative;
        left: -52px;
    }
}

.login_page_bg{
    width: 100%;
    background: url('../images/bg.png') no-repeat;
    background-repeat: repeat;
    background-size: cover;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.02));
    height: 100vh;
}
.border_bottom{
    border-bottom: 1px solid #ddd;
}
.flex-header-view{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.title_pos{
    position: relative;
    left: 35px;
}
.card_fp_shadow {
    border: 0px;
    background: #FFFFFF;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 20px 20px 30px 10px;
    margin-top: 4rem;
  }
  #basic-navbar-nav a{
    text-decoration: none;
  }
  .pr_img_pos{
    width: 350px;
    margin: auto;
    display: block;
  }