.bg_red_footer{
    background-color: #fd000a;
    padding: 50px;
}
.elementor-icon-list-items li{
   margin-top: 10px;
   font-size: 12px;
   font-weight: bold;
}
.elementor-icon-list-items li a{
   text-decoration: none;
}
.elementor-icon-list-items li a span{
    color: #fff;
}
.bg_yellow-footer{
    background-color: #ffcb05;
    padding: 10px;
}

